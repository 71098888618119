import React, { useContext, useMemo, useState, useCallback } from 'react';
import { Row, Col, Card, Image, ProgressBar, Button, Modal } from 'react-bootstrap';
import UserContext from '../../contexts/UserContext';
import LoyaltyContext from '../../contexts/LoyaltyContext';

import LoadingSpinner from './../utils/loadingSpinner/LoadingSpinner';
import RedeemRewardButton from '../rewards/RedeemRewardButton';
import AnnouncementsCarousel from './../announcements/AnnoucementCaorusel';
import './Home.css';
import { Link } from 'react-router-dom';


const Home = () => {
    const [showBenefits, setShowBenefits] = useState(false);
    const { points, tier_points = 0, tier } = useContext(UserContext);
    const { tierData: tiers, rewards, isLoadingConfig, pointConfig, isLoadingRewards } = useContext(LoyaltyContext);


    const rewardsMap = useMemo(() => {

        if (rewards.length > 0) {
            const groupedRewards = rewards.sort((a, b) => a.points - b.points).reduce((result, item) => {
                if (item.points <= points) {
                    result.eligible.push(item);
                }
                else {
                    result.locked.push(item);
                }
                return result;
            }, { eligible: [], locked: [] });

            if (groupedRewards.eligible.length >= 5) {
                return { list: groupedRewards.eligible.slice(groupedRewards.eligible.length - 5, groupedRewards.eligible.length), eligibleCount: groupedRewards.eligible.length }
            }

            return { list: [...groupedRewards.eligible, ...groupedRewards.locked].slice(0, 5), eligibleCount: groupedRewards.eligible.length };
            // const eligibleRewards = rewards.filter(({ points: rewardPoints }) => rewardPoints <= points).slice(0, 5).sort((a, b) => b.points - a.points);
        }
        return { list: [], eligibleCount: 0 }
    }, [rewards, points])

    const toggleShowBenefits = useCallback(() => {
        setShowBenefits(!showBenefits);
    }, [setShowBenefits, showBenefits])

    const currentTier = useMemo(() => {
        if (tier && Object.keys(tiers).length > 0) {
            return Object.values(tiers).find(({ name }) => name === tier);
        }
        return null;
    }, [tier, tiers])

    if (isLoadingConfig) {
        return <LoadingSpinner />
    }


    const nextTier = Object.values(tiers).sort(((a, b) => a.points - b.points)).find(item => item.points > tier_points);

    const { alias: pointAlias } = pointConfig;


    return (
        <>

            <Row>
                <Col lg={6} sm={12}>
                    <Row className="my-4">
                        {currentTier ?
                            <>
                                <Col xs={6} className="text-left">
                                    <Image src={currentTier.imageUrl} alt={currentTier.name} width="32" />
                                </Col>
                                <Col xs={6} className="text-right">
                                    <Image src={nextTier.imageUrl} alt={nextTier.name} width="32" />
                                </Col>
                                <Col xs={12} className="my-2">
                                    <ProgressBar now={(tier_points / nextTier.points) * 100} label={<div className="text-center text-dark progress-bar-label">{nextTier.points - tier_points} {pointAlias} needed</div>} />
                                </Col>

                                <Col xs={4} className="text-left text-primary tier-info">
                                    <h6 className="mb-0 font-weight-bold">{currentTier.points}</h6>
                                    <p className="font-weight-bold">{currentTier.name}</p>
                                    <small>Current Tier</small>
                                </Col>
                                <Col xs={4}>
                                    <Button size="sm" variant="outline-primary" className="my-2" onClick={toggleShowBenefits}>View Benefits</Button>
                                </Col>
                                <Col xs={4} className="text-right text-primary tier-info">
                                    <h6 className="mb-0 font-weight-bold">{nextTier.points}</h6>
                                    <p className="font-weight-bold">{nextTier.name}</p>
                                    <small>Next Tier</small>
                                </Col>
                            </>
                            :
                            <Col xs={12}>
                                <LoadingSpinner />
                            </Col>
                        }
                    </Row>
                    <div>
                        <Card className="mb-2 mt-4 mt-lg-0">
                            <div className="p-2 d-flex align-items-center">
                                <img src="./assets/images/announcement.png" alt="rewards logo" className="mr-2" /><small>Announcements</small>
                            </div>
                        </Card>
                        <AnnouncementsCarousel />
                    </div>
                </Col>
                <Col lg={6} sm={12}>
                    <Card className="mb-2 mt-4 mt-lg-0">
                        <div className="p-2 d-flex align-items-center">
                            <img src="./assets/images/rewards.png" alt="rewards logo" className="mr-2" /><small>You have unlocked {rewardsMap.eligibleCount} rewards.</small>
                        </div>
                    </Card>
                    {isLoadingRewards ?
                        <LoadingSpinner /> :
                        rewardsMap.list.length > 0 ?
                            <>
                                {rewardsMap.list.map((reward) => {
                                    return (
                                        <Card key={reward._id} className="mb-2">
                                            <div className="p-3 d-flex flex-row align-items-center">

                                                <img src={reward.imageUrls} alt="rewards logo" className="mr-2 reward-icon" />
                                                <div className="flex-fill">
                                                    <small className="text-capitalize font-weight-bold">{reward.name}</small>
                                                    <div className="text-muted text-smaller line-height-p5 pb-2"><strong>{reward.points}</strong> {pointAlias}</div>
                                                </div>
                                                {reward.points <= points ? <RedeemRewardButton id={reward._id} className="ml-auto" /> :
                                                    <div><img src="/assets/images/lock.png" height="24" alt="lock" /></div>
                                                }


                                            </div>
                                        </Card>
                                    )
                                })}
                                <div className="text-center">
                                    <Link to="/rewards" className="text-decoration-none">View Rewards</Link>
                                </div>
                            </> :
                            <p className="text-center text-muted">No rewards.</p>
                    }
                </Col>
            </Row>


            <Modal show={showBenefits} onHide={toggleShowBenefits}>
                <Modal.Header closeButton>
                    <Modal.Title>Tier Benefits</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        {currentTier && currentTier.benefits.map((item, index) => {
                            return (<li key={`t-${index}`}>{item}</li>)
                        })
                        }
                    </ul>

                </Modal.Body>
                <Modal.Footer>
                    <Link to="/about"><Button variant="primary">View Other Tiers</Button></Link>
                    <Button variant="secondary" onClick={toggleShowBenefits}>
                        Close
          </Button>

                </Modal.Footer>
            </Modal>

        </>
    )
}
export default Home;