import React, { createContext, useReducer, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { getCurrentUser, getLoyaltyProfile } from './../services';
import Amplify from '@aws-amplify/core';
// import { UtilService, ShoutOUTService } from './../services/Services';
// import { initialize } from './../redux/actions/CommonActions';
// import { withRouter } from 'react-router-dom';
// import QueryString from 'query-string';
// import fetchIntercept from "fetch-intercept";



const InitialUserContext = {
    isAuth: false,
    initComplete: false,
    username: '',
    email: '',
    mobile_number: '',
    name: '',
    points: 0,
    tier: '',
    tier_points: 0
};


const USER_ACTIONS = {
    LOGIN: "login",
    LOGOUT: "logout",

    INIT_COMPLETE: "userInitCompleted",
    UPDATE_PROFILE: "updateProfile"
}

const Context = createContext();
const reducer = (state, { type, ...rest }) => {
    console.debug("Action:", type)
    switch (type) {
        case USER_ACTIONS.LOGIN:
            {

                // const { so_user_name: username, so_user_email: email, so_user_role: userRole, so_user_id: ownerId, product } = UtilService.decodeToken(rest.token);

                // if (product !== Constants.BUILD_APP) {
                //     localStorage.removeItem("token");
                //     if(product === Constants.APP_LOYALTY){
                //         window.location.replace(Constants.LOYALTY_APP_URL  + "?token=" + rest.token);
                //     }else{
                //         window.location.replace(Constants.ENGAGE_APP_URL + "?token=" + rest.token);
                //     }

                // }


                return { ...state, isAuth: true, ...rest }
            }

        case USER_ACTIONS.LOGOUT: {
            return { ...InitialUserContext, initComplete: true }
        }



        case USER_ACTIONS.INIT_COMPLETE: {
            return { ...state, initComplete: true };
        }

        case USER_ACTIONS.UPDATE_PROFILE: {
            return {
                ...state, ...rest
            }
        }
        default: {
            return state;
        }
    }
};



const UserContextContainer = (props) => {
    const [state, dispatch] = useReducer(reducer, InitialUserContext);
    const history = useHistory();
    const logout = useCallback(() => {
        dispatch({ type: USER_ACTIONS.LOGOUT });
        history.push("/login");

    }, [dispatch, history]);

    const loadLoyaltyProfile = useCallback(async () => {
        try {
            const loyaltyProfileResponse = await getLoyaltyProfile();

            dispatch({ type: USER_ACTIONS.UPDATE_PROFILE, ...loyaltyProfileResponse });
        } catch (e) {

        }
    }, [dispatch])

    const login = useCallback(async ({ username }) => {

        dispatch({ type: USER_ACTIONS.LOGIN, username });
        loadLoyaltyProfile();
        history.push("/");

    }, [dispatch, loadLoyaltyProfile, history]);


    useEffect(() => {

        const init = async () => {
            Amplify.configure({
                Auth: {

                    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
                    //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

                    // REQUIRED - Amazon Cognito Region
                    region: 'us-east-1',


                    // OPTIONAL - Amazon Cognito User Pool ID
                    userPoolId: 'us-east-1_eOZpOG5bX',

                    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
                    userPoolWebClientId: '2d25u8725032stgh08k762enk9',

                    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
                    mandatorySignIn: false,

                    // OPTIONAL - Configuration for cookie storage
                    // cookieStorage: {
                    // 	// REQUIRED - Cookie domain (only required if cookieStorage is provided)
                    // 	domain: '.yourdomain.com',
                    // 	// OPTIONAL - Cookie path
                    // 	path: '/',
                    // 	// OPTIONAL - Cookie expiration in days
                    // 	expires: 365,
                    // 	// OPTIONAL - Cookie secure flag
                    // 	secure: true
                    // },

                    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
                    authenticationFlowType: 'USER_PASSWORD_AUTH'
                }
            });
            try {
                const userResponse = await getCurrentUser();

                login(userResponse)

            } catch (e) {
                console.error("Error loading profile:", e);
            } finally {
                dispatch({ type: USER_ACTIONS.INIT_COMPLETE });
            }
            // getCurrentUser().then(res => {
            // 	userContext.isAuth = true;
            // 	LoyaltyService.getProfile().then(res => {
            // 		const isProfileLinked = res && Object.keys(res).length > 0;
            // 		this.setState({ userContext: { ...userContext, ...res, isProfileLinked } });
            // 	}, err => {
            // 		console.error(err);
            // 	});
            // 	route('/allrewards');

            // }).catch(err => {
            // 	userContext.isAuth = false;
            // 	this.setState({ userContext });

            // })
        }

        // const token = localStorage.getItem('token');
        // if (token) {
        //     dispatch({ type: USER_ACTIONS.LOGIN, token });
        // }

        // dispatch({ type: USER_ACTIONS.INIT_COMPLETE });
        init();
        return () => { console.debug("Unmount user context") }
    }, []);  //eslint-disable-next-line




    const value = { ...state, login, logout, loadLoyaltyProfile };
    console.debug("Context:", value);
    return (
        <Context.Provider value={value}>{props.children}</Context.Provider>
    );
}

export default Context;

export { InitialUserContext, UserContextContainer };