import React, { Suspense } from 'react';
import {
  BrowserRouter as Router
} from "react-router-dom";

import { UserContextContainer } from './contexts/UserContext';
import ErrorBoundary from './ErrorBoundary';
import './vendor/theme/bootstrap-theme.css';
import { LoyaltyContextContainer } from './contexts/LoyaltyContext';
import { UtilContextContainer } from './contexts/UtilContext';
import AlertBox from './components/utils/alert/AlertBox';
import LoadingSpinner from './components/utils/loadingSpinner/LoadingSpinner';
import AppRouter from './AppRouter';



function App() {
  return (
    <div className="App h-100">
      <ErrorBoundary>
        <Suspense fallback={<LoadingSpinner />}>
          <Router>
            <UtilContextContainer>
              <UserContextContainer>
                <LoyaltyContextContainer>
                  <AppRouter />

                  <AlertBox />
                </LoyaltyContextContainer>
              </UserContextContainer>
            </UtilContextContainer>
          </Router>
        </Suspense>

      </ErrorBoundary>
    </div>
  );
}

export default App;
