import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import './PortalMainNavbar.css';
const PortalMainNavbar = () => {
    const location = useLocation();
    return (
        <Navbar className="shadow py-0" bg="white" expand="lg" id="topNavbar">
            <Navbar.Brand href="/" className="d-md-none">Vision Care Logo</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav fill className="w-100">
                    <Nav.Item>
                        <Nav.Link as={Link} href="/" to="/" active={location.pathname === '/'}>Home</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} href="/rewards" to="/rewards" active={location.pathname === '/rewards'}>Rewards</Nav.Link>
                        {/* <Nav.Link as={Link} href="/tiers" to="/tiers" active={location.pathname === '/tiers'}>Tiers</Nav.Link> */}
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} href="/transactions" to="/transactions" active={location.pathname === '/transactions'}>Transactions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} href="/announcements" to="/announcements" active={location.pathname === '/announcements'}>Announcements</Nav.Link>
                        {/* <Nav.Link as={Link} href="/myrewards" to="/myrewards" active={location.pathname === '/myrewards'}>My Rewards</Nav.Link> */}
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} href="/about" to="/about" active={location.pathname === '/about'}>About</Nav.Link>
                    </Nav.Item>
                </Nav>

            </Navbar.Collapse>
        </Navbar>
    )
}
export default PortalMainNavbar;