import Auth from '@aws-amplify/auth';
import { fetchPost } from './CommonServiceUtils';
const LOYALTY_SERVICE_URL = "https://api.getshoutout.com/portalservice/";

const login = ({ mobileNumber, password }) => {
    return Auth.signIn('+' + mobileNumber, password);
}

const signup = ({ loyaltyNo, mobileNumber, password }) => {
    const phoneNumber = '+' + mobileNumber;
    return Auth.signUp({
        username: phoneNumber,
        password: password,
        attributes: {
            phone_number: phoneNumber,   // optional - E.164 number convention
            // other custom attributes 
        },
        clientMetadata: {
            loyaltyId: loyaltyNo,
            referrerUrl: window.location.origin
        }
    });
}

const signout = () => {
    return Auth.signOut();
}

const confirmSignup = (mobileNumber, code) => {

    return fetchPost(LOYALTY_SERVICE_URL + "confirmuser",
        {
            mobileNumber: mobileNumber,
            otpCode: code
        }, false);

}

const resendConfirmSignupCode = (mobileNumber) => {
    return fetchPost(LOYALTY_SERVICE_URL + "otpresend",
        {
            mobileNumber: '+' + mobileNumber
        }, false);
}

const getCurrentUser = () => {
    return Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
}

const getAccessToken = () => {
    return Auth.currentSession().then(res => {
        return res.getAccessToken().getJwtToken();
    }).catch(err => {
        return null;
    })
}

const getIdToken = () => {
    return Auth.currentSession().then(res => {
        return res.getIdToken().getJwtToken();
    }).catch(err => {
        return null;
    })
}


const forgotPassword = (mobileNumber) => {
    return fetchPost(`${LOYALTY_SERVICE_URL}resetpassword?action=request`, { mobileNumber: mobileNumber },false);
}

const forgotPasswordSubmit = (mobileNumber, code, newPassword) => {
    return fetchPost(`${LOYALTY_SERVICE_URL}resetpassword?action=update`, { mobileNumber, otpCode: code, password: newPassword },false);
}
export { login, signout, getAccessToken, getCurrentUser, resendConfirmSignupCode, confirmSignup, getIdToken, forgotPassword, forgotPasswordSubmit, signup }