import React, { useContext, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import UserContext from './../../contexts/UserContext';
import { Link } from 'react-router-dom';
import Avatar from './../utils/Avatar/Avatar';
import './SideProfileView.css';
import LoyaltyContext from '../../contexts/LoyaltyContext';
import LoadingSpinner from '../utils/loadingSpinner/LoadingSpinner';

const SideProfileView = () => {
    const { name, email, mobile_number, points, tier } = useContext(UserContext);
    const {tierData: tiers,  pointConfig, isLoadingConfig } = useContext(LoyaltyContext);
    const { alias: pointAlias } = pointConfig;

    const currentTier = useMemo(() => {
        if (tier && Object.keys(tiers).length > 0) {
            return Object.values(tiers).find(({ name }) => name === tier);
        }
        return null;
    }, [tier, tiers])

    // console.log(currentTier.name)
    
    return (
        <Card>
            <Card.Body>
                <img src="/assets/images/logo.png" alt="logo" width="" className="img-fluid px-lg-3 px-xl-5 pt-lg-2 pt-md-1" />
                <hr className="border-muted dashed" />
                <div className="text-center my-3">
                    <small>Welcome to Loyalty</small>
                </div>
                <div className="d-flex flex-column profile-container">
                    <div className="text-center mt-auto">
                        {currentTier?   <Avatar text={name} image={currentTier.imageUrl} /> : <LoadingSpinner/>}                      
                        <h6 className="my-4">{name}</h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center my-2">
                        <div className="border-dashed p-2">
                            <h5 className="font-weight-bold mb-0">{points}</h5>
                            <small className="text-muted text-extra-smaller">Redeemable {pointAlias}</small>
                        </div>
                        <div className="border-dashed p-2">                          
                            <h5 className="font-weight-bold mb-0">{tier}</h5>
                            <small className="text-muted text-extra-smaller">Current Tier</small>
                        </div>
                    </div>
                    {/* <div className="shadow p-3 d-flex justify-content-between my-2">
                        <small className="text-muted">Phone</small>
                        <small>{mobile_number}</small>
                    </div>

                    <div className="shadow p-3 d-flex justify-content-between my-2">
                        <small className="text-muted">Email</small>
                        <small>{email}</small>
                    </div> */}
                    <Link className="btn btn-outline-dark btn-sm rounded-0 my-2 float-right mt-auto  w-50 ml-auto" to="/logout">Logout</Link>
                </div>
            </Card.Body>
        </Card>
    )
}
export default SideProfileView;