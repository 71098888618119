import React, { createContext, useReducer, useEffect, useContext } from "react";
import { getRewards, getLoyaltyConfig, getAnnouncements } from './../services';
import UserContext from './UserContext';
const InitialLoyaltyContext = {
    rewards: [],
    isLoadingRewards: false,
    isLoadingConfig: false,
    tierData: {},
    tierConfig: {},
    rewardConfig: {},
    pointConfig: {},
    announcements: [],
    isLoadingAnnouncements: false
};


const LOYALTY_ACTIONS = {
    RECEIVED_REWARDS: 'RECEIVED_REWARDS',
    LOADING_REWARDS: 'LOADING_REWARDS',
    LOADING_CONFIG: 'LOADING_CONFIG',
    RECEIVED_CONFIG: 'RECEIVED_CONFIG',
    TOGGLE_LOADING_ANNOUNCEMENTS: 'TOGGLE_LOADING_ANNOUNCEMENTS',
    RECEIVED_ANNOUNCEMENTS: 'RECEIVED_ANNOUNCEMENTS'
};

const Context = createContext();
const reducer = (state, { type, ...rest }) => {

    switch (type) {
        case LOYALTY_ACTIONS.LOADING_REWARDS: {
            return { ...state, isLoadingRewards: true }
        }
        case LOYALTY_ACTIONS.RECEIVED_REWARDS: {
            const sortedRewards = rest.rewards.sort((a, b) => a.points - b.points);
            return { ...state, rewards: sortedRewards, isLoadingRewards: false }
        }
        case LOYALTY_ACTIONS.LOADING_CONFIG: {
            return { ...state, isLoadingConfig: true }
        }
        case LOYALTY_ACTIONS.RECEIVED_CONFIG: {
            const { tiers, rewards, points } = rest.config;
            const newState = { ...state, isLoadingConfig: false };
            if (tiers && tiers.data) {
                newState['tierData'] = tiers.data;
            }
            if (tiers && tiers.config) {
                newState['tierConfig'] = tiers.config;
            }
            if (rewards) {
                newState['rewardConfig'] = rewards;
            }
            if (points) {
                newState['pointConfig'] = points;
            }
            return newState;
        }
        case LOYALTY_ACTIONS.LOADING_ANNOUNCEMENTS: {
            return { ...state, isLoadingAnnouncements: !state.isLoadingAnnouncements };
        }
        case LOYALTY_ACTIONS.RECEIVED_ANNOUNCEMENTS: {
            return { ...state, announcements: rest.announcements, isLoadingAnnouncements: false };
        }
        default: {
            return state;
        }
    }
};



const LoyaltyContextContainer = (props) => {
    const [state, dispatch] = useReducer(reducer, InitialLoyaltyContext);
    const { isAuth } = useContext(UserContext);

    useEffect(() => {
        const init = async () => {
            dispatch({ type: LOYALTY_ACTIONS.LOADING_REWARDS });
            dispatch({ type: LOYALTY_ACTIONS.LOADING_CONFIG });
            try {
                const loyaltyConfigResponse = await getLoyaltyConfig();
                dispatch({ type: LOYALTY_ACTIONS.RECEIVED_CONFIG, config: loyaltyConfigResponse });
            } catch (e) {

            }

            try {
                const rewardsResponse = await getRewards();
                dispatch({ type: LOYALTY_ACTIONS.RECEIVED_REWARDS, rewards: rewardsResponse });
                console.debug("Rewards:", rewardsResponse);
            } catch (e) {

            }

        }

        init();

    }, []);  //eslint-disable-next-line


    useEffect(() => {
        if (isAuth) {
            const initAuth = async () => {
                dispatch({ type: LOYALTY_ACTIONS.TOGGLE_LOADING_ANNOUNCEMENTS });
                try {

                    const announcementsResponse = await getAnnouncements();
                    dispatch({ type: LOYALTY_ACTIONS.RECEIVED_ANNOUNCEMENTS, announcements: announcementsResponse });

                } catch (e) {

                } finally {
                    dispatch({ type: LOYALTY_ACTIONS.TOGGLE_LOADING_ANNOUNCEMENTS });
                }
            }

            initAuth();
        }
    }, [isAuth])

    const value = { ...state };
    console.debug("Loyalty Context:", value)
    return (
        <Context.Provider value={value}>{props.children}</Context.Provider>
    );
}

export default Context;

export { LoyaltyContextContainer };