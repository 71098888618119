import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './../home/Home';
import SideProfileView from './../profile/SideProfileView';
import { Row, Col, Card } from 'react-bootstrap';
import PortalMainNavbar from './../navbars/PortalMainNavbar';
import LoadingSpinner from './../utils/loadingSpinner/LoadingSpinner';

const Profile = React.lazy(() => import('./../profile/Profile'));
const RewardsTab = React.lazy(() => import('./../rewards/RewardsTab'));
// const Tiers = React.lazy(() => import('./../tiers/Tiers'));
const Transactions = React.lazy(() => import('./../transactions/Transactions'));
// const MyRewards = React.lazy(() => import('./../rewards/MyRewards'));
const About = React.lazy(() => import('./../about/About'));
const Announcements = React.lazy(() => import('./../announcements/Announcements'));
const Portal = () => {
    return (<div id="portal-view" className="container h-100">
        <Row className="h-100">
            <Col className="my-3">
                <Row>
                    <Col md={3} className="d-none d-md-block">
                        <SideProfileView />
                    </Col>
                    <Col>
                        <PortalMainNavbar />
                        <Card className="mt-4">
                            <Card.Body>
                                <Suspense fallback={<LoadingSpinner />}>
                                    <Switch>
                                        <Route exact path="/profile" component={Profile} />
                                        <Route exact path="/rewards" component={RewardsTab} />
                                        {/* <Route exact path="/tiers" component={Tiers} /> */}
                                        <Route exact path="/transactions" component={Transactions} />
                                        <Route exact path="/announcements" component={Announcements} />
                                        {/* <Route exact path="/myrewards" component={MyRewards} /> */}
                                        <Route exact path="/about" component={About} />
                                        <Route component={Home} />
                                    </Switch>
                                </Suspense>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Col>
        </Row>

    </div>)
}
export default Portal;