import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import UtilContext from './../../../contexts/UtilContext';
const AlertBox = () => {
    const { alert, toggleAlert } = useContext(UtilContext);
    const { title, body, isShow, alertType } = alert;
    return (
        <Modal show={isShow} onHide={toggleAlert}>
            <Modal.Header closeButton>
                <Modal.Title className={`text-${alertType}`}>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>

                <Button variant={alertType} size="sm" onClick={toggleAlert}>
                    Close
      </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default AlertBox;