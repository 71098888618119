import React, { useContext } from 'react';
import Portal from './components/portal/Portal';

import {
    Switch,
    Route
} from "react-router-dom";

import UserContext from './contexts/UserContext';
const Signup = React.lazy(() => import('./components/account/signup/Signup'));
const SignupVerify = React.lazy(() => import('./components/account/signup/SignupVerify'));
const Logout = React.lazy(() => import('./components/account/Logout'));
const Login = React.lazy(() => import('./components/account/Login'));
const ForgotPassword = React.lazy(() => import('./components/account/ForgotPassword'));
const LandingPage = React.lazy(() => import('./components/landingpage/LandingPage'));

const AppRouter = () => {
    const { isAuth } = useContext(UserContext);
    return (
        <Switch>


            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/signupverify" component={SignupVerify} />
            <Route exact path="/forgetpassword" component={ForgotPassword} />
            <Route exact path="/logout" component={Logout} />

            <Route component={isAuth ? Portal : LandingPage} />


        </Switch>
    )
}
export default AppRouter;