// import { getAccessToken } from './index';
import { getIdToken } from './index';

const getHeaders = async (isAuth) => {
    const headers = { 'Content-Type': 'application/json' };
    if (isAuth) {
        headers['Authorization'] = await getIdToken();
    }
    return headers;
}
const handleErrors = (response) => {
    try {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    } catch (e) {
        throw Error(response.statusText);
    }
}

const parseJson = (response) => {
    try {
        return response.json();
    } catch (e) {
        console.error("Cannot parse:", response);
        throw Error("Can not parse");
    }
}
const fetchGet = async (url, isAuth = true) => {

    return fetch(url, { headers: await getHeaders(isAuth) }).then(handleErrors).then(parseJson);
}

const fetchPost = async (url, body, isAuth = true) => {

    return fetch(url, { headers: await getHeaders(isAuth), method: 'POST', body: JSON.stringify(body) }).then(handleErrors).then(parseJson);


}



export { fetchGet, fetchPost };