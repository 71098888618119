import React, { useContext } from 'react';


import LoadingSpinner from './../utils/loadingSpinner/LoadingSpinner';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import LoyaltyContext from './../../contexts/LoyaltyContext';
import "react-responsive-carousel/lib/styles/carousel.min.css";
const AnnouncementsCarousel = () => {
    const { announcements, isLoadingAnnouncements: isLoading } = useContext(LoyaltyContext);

    return (
        <>
            {isLoading ? <LoadingSpinner /> :

                <Carousel emulateTouch showArrows={true} showThumbs={false} showStatus={false} infiniteLoop={true} centerMode centerSlidePercentage={100} >

                    {announcements.slice(0, 5).map(({ _id: id, imageUrl }) => {
                        return (
                            <div key={id}>
                                <img src={imageUrl} />
                            </div>


                        )
                    }
                    )
                    }
                    <div>
                        <p className="legend"><Link to="/announcements" className="text-decoration-none text-white">View More</Link></p>
                    </div>
                </Carousel>
            }

        </>

    )
}
export default AnnouncementsCarousel;