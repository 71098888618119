import React, { useState, useContext, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { redeemReward } from './../../services';
import UtilContext from './../../contexts/UtilContext';
import UserContext from '../../contexts/UserContext';
const RedeemRewardButton = ({ id, ...rest }) => {
    const [isRedeeming, setIsRedeeming] = useState(false);
    const { toggleAlert } = useContext(UtilContext);
    const { loadLoyaltyProfile } = useContext(UserContext);
    const [redeemConfirm, setRedeemConfirm] = useState(false);

    const onClickRedeemReward = useCallback(async () => {
        try {
            setIsRedeeming(true);
            await redeemReward(id);
            toggleAlert({ alertType: "success", title: "Redeem success", body: "You can check your reward inside my rewards." });
            loadLoyaltyProfile();
        } catch (e) {
            console.error(e);
            toggleAlert({ alertType: "danger", title: "Redeem not success", body: "Please try again later!" });
        } finally {
            setIsRedeeming(false);
            setRedeemConfirm(false);
        }

    }, [setIsRedeeming, setRedeemConfirm, id,loadLoyaltyProfile,toggleAlert])

    const redeemConfirmation = useCallback(() => {
        setRedeemConfirm(!redeemConfirm);
    }, [setRedeemConfirm, redeemConfirm])



    return (redeemConfirm ?
        <div  {...rest}>
            <Button variant="outline-success" size="sm" className="mr-2" onClick={onClickRedeemReward} disabled={isRedeeming}>{isRedeeming ? "Redeeming..." : "Confirm"}</Button>
            <Button variant="outline-danger" size="sm" onClick={redeemConfirmation} disabled={isRedeeming} >Cancel</Button>
        </div> :
        <Button variant="outline-primary" size="sm" onClick={redeemConfirmation} disabled={isRedeeming} {...rest}>{isRedeeming ? "Redeeming..." : "Redeem"}</Button>
    )
}
export default RedeemRewardButton;